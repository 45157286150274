




<div class="content" role="main">
</div>

<div class="fundo">


</div>
<div class="arrives">

    <br>
    <div aria-label="" class="toolbar" role="banner">
        
        <span class="texto">CHEGADAS - ARRIVES</span>
        <div class="spacer"></div>
    </div>


    <table style="width:100%">
        <tr>
            <th class="fixedCol"> <span class="txtPor">CIA </span> <span class="txtEng"> AIRLINE</span></th>
            <th class="fixedCol2"><span class="txtPor">VOO </span> <span class="txtEng"> FLIGHT</span></th>
            <th class="fixedCol3"><span class="txtPor">ORIGEM </span> <span class="txtEng"> FROM</span></th>
            <th class="fixedCol4"><span class="txtPor">HORA </span> <span class="txtEng"> TIME</span></th>
            <th class="fixedCol5"><span class="txtPor">SITUAÇÃO</span> <span class="txtEng"> SITUATION</span></th>
        </tr>
        <tr *ngFor="let flight of flightsIn" class="texto2">
            <td *ngIf="flight.empresa == 'AZU'" class="fixedCol azul_background"></td>
            <td *ngIf="flight.empresa == 'GLO'" class="fixedCol gol_background"></td>
            <td *ngIf="flight.empresa == 'LAN'" class="fixedCol latam_background"></td>
            <td><font class="texto2"></font> {{flight.voo}}</td>
                
            <td><font class="texto2"></font> {{flight.cidade}}<span>&nbsp;/&nbsp;</span>{{flight.aeroporto}}</td>
            <td  (click)="adjusteTime()" *ngIf="flight.estimativa == '' "><font class="texto2"></font> {{flight.hora}}</td>
            <td (click)="adjusteTime()" *ngIf="flight.estimativa != '' "><font class="texto2"></font> {{flight.hora}}</td>
            <td class="{{flight.cor}}"><font color="{{flight.status2}}"><font class="texto2"></font> {{flight.status}}</font></td>

          

        </tr>

        <tr *ngIf="flightsInTomorow.length > 0">
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor"></span></th>
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor">TOMORROW</span></th>
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor">TOMORROW</span></th>
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor">TOMORROW</span></th>
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor">TOMORROW</span></th>
        </tr>
        <tr *ngFor="let flight of flightsInTomorow" class="texto2">
            <td *ngIf="flight.empresa == 'AZU'" class="fixedCol azul_background"></td>
            <td *ngIf="flight.empresa == 'GLO'" class="fixedCol gol_background"></td>
            <td *ngIf="flight.empresa == 'LAN'" class="fixedCol latam_background"></td>
            <td><font class="texto2"></font> {{flight.voo}}</td>
            <td><font class="texto2"></font> {{flight.cidade}}<span>&nbsp;/&nbsp;</span>{{flight.aeroporto}}</td>
            <td (click)="adjusteTime()" *ngIf="flight.estimativa == '' "><font class="texto2"></font> {{flight.hora}}</td>
            <td (click)="adjusteTime()" *ngIf="flight.estimativa != '' "><font class="texto2"></font> {{flight.hora}}</td>
            <td class="{{flight.cor}}"><font color="{{flight.status2}}"><font class="texto2"></font> {{flight.status}}</font></td>

        </tr>


    </table>
</div>

<div class="departures">
    <br>
    <div class="toolbarB" role="banner">

        <span class="texto7">PARTIDAS - DEPARTURES</span> 
        <div class="spacer"></div>
    </div>

    <table style="width:100%">
        <tr>
            <th class="fixedCol"> <span class="txtPor">CIA </span> <span class="txtEng"> AIRLINE</span></th>
            <th class="fixedCol2"><span class="txtPor">VOO </span> <span class="txtEng"> FLIGHT</span></th>
            <th class="fixedCol3"><span class="txtPor">DESTINO  </span> <span class="txtEng"> TO</span></th>
            <th class="fixedCol4"><span class="txtPor">HORA </span> <span class="txtEng"> TIME</span></th>
            <th class="fixedCol5"><span class="txtPor">SITUAÇÃO</span> <span class="txtEng"> SITUATION</span></th>


        </tr>
        <tr *ngFor="let flight of flightsOut" class="texto2">
            <td *ngIf="flight.empresa == 'AZU'" class="fixedCol azul_background"></td>
            <td *ngIf="flight.empresa == 'GLO'" class="fixedCol gol_background"></td>  
            <td *ngIf="flight.empresa == 'LAN'" class="fixedCol latam_background"></td>
            <td>{{flight.voo}}</td>
            <td>{{flight.cidade}}<span>&nbsp;/&nbsp;</span>{{flight.aeroporto}}</td>
            <td *ngIf="flight.estimativa == '' " (click)="adjusteTime()">{{flight.hora}}</td>
            <td (click)="adjusteTime()" class="" *ngIf="flight.estimativa != '' ">{{flight.hora}}</td>
            <td class="{{flight.cor}}"><font color="{{flight.status2}}">{{flight.status}}</font></td>       

        </tr>
        <tr *ngIf="flightsOutTomorow.length > 0">
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor"></span></th>
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor">TOMORROW</span></th>
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor">TOMORROW</span></th>
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor">TOMORROW</span></th>
            <th class="fixedCol2" style="background-color:#000000"><span class="txtEng">AMANHÃ </span> &nbsp; <span class="txtPor">TOMORROW</span></th>
        </tr>
        <tr *ngFor="let flight of flightsOutTomorow" class="texto2">
            <td *ngIf="flight.empresa == 'AZU'" class="fixedCol azul_background"></td>
            <td *ngIf="flight.empresa == 'GLO'" class="fixedCol gol_background"></td>
            <td *ngIf="flight.empresa == 'LAN'" class="fixedCol latam_background"></td>
            <td>{{flight.voo}}</td>
            <td>{{flight.cidade}}<span>&nbsp;/&nbsp;</span>{{flight.aeroporto}}</td>
            <td (click)="adjusteTime()" *ngIf="flight.estimativa == '' ">{{flight.hora}}</td>
            <td (click)="adjusteTime()" *ngIf="flight.estimativa != '' ">{{flight.hora}}</td>
            <td class="{{flight.cor}}"><font color="{{flight.status2}}">{{flight.status}}</font></td>

        </tr>


    </table>
</div>





<div class="footer" role="banner">

    
    <!-- <div class="cascavel_background">

    </div> -->

    <span class="texto3"> Aeroporto &nbsp; Regional&nbsp;  de &nbsp; Cascavel.&nbsp; Atualizado&nbsp; as:&nbsp; {{lastupdate}}</span>

  <!--   <span class="texto3"> Aeroporto Municipal de Toledo - Luiz Dalcanale Filho. Atualizado as: {{lastupdate}}</span> -->

    



    <!-- <div class="spacer"></div> -->
  <!--   <span class="texto3"> Departamento de Informática SBCA. Atualizado as: {{lastupdate}}
      </span>
 -->
</div>
<router-outlet></router-outlet>