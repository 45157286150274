import { Component , OnInit} from '@angular/core';

import { DataService } from '../services/data.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'meusvoos';
  flightsIn = [];
  flightsOut = [];
  flightsInTomorow = [];
  flightsOutTomorow = [];
  lastupdate = "";
  timeIncrement = 0;
  timeStep = 3600000;
  timeMax = 10800000;

  constructor(
    private dataService: DataService,
  ) { }


ngOnInit() {
  this.loadItems();
  this.update();
}

  loadItems()
{
  let timerId = setInterval(() => this.update(), 60000);

}




async update(){

  var fin = await this.dataService.getIN();
  var fout = await this.dataService.getOUT();

  this.flightsIn = [];
  this.flightsOut = [];
  this.flightsInTomorow = [];
  this.flightsOutTomorow = [];

  fin.forEach(element => {
   // console.log(this.flightsIn.length);
    //console.log(this.flightsInTomorow.length);
    if (element.tomorow == false){
      if((this.flightsIn.length +  this.flightsInTomorow.length) < 8 ){
        this.flightsIn.push(element);
      //  console.log("h:"+element.hora);
    }
    }
    else
    {
      if((this.flightsIn.length +  this.flightsInTomorow.length) < 8 ){
        this.flightsInTomorow.push(element);
       // console.log("t:"+element.hora);
      }

    }
  });





  fout.forEach(element => {
    if (element.tomorow){
      if((this.flightsOut.length +  this.flightsOutTomorow.length) < 8 ){
        this.flightsOutTomorow.push(element);
      }
    }
    else{
      if((this.flightsOut.length +  this.flightsOutTomorow.length) < 8 ){
        this.flightsOut.push(element);
      }
    }
  });



  var d = new Date();
  var h = d.getHours();
  var m = d.getMinutes();
  var min = m < 10 ? '0'+m : m;
  var strTime = h + ':' + min + ' ' ;

  this.lastupdate = strTime;


}


adjusteTime(event){
  console.log("aceitou");
  this.timeIncrement = parseInt(localStorage.getItem('timeIncrement') ?? "0");
  var newtime = this.timeIncrement + this.timeStep;
  if(newtime > this.timeMax){
    newtime = this.timeMax * -1;
  }
  localStorage.setItem('timeIncrement', newtime + "");
  this.update();
}



}


