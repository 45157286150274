import { NgIf } from '@angular/common';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

export class Flight {
  empresa = "";
  voo = "";
  cidade = "";
  aeroporto = "";
  aeroporto2 = "";

  hora = "";
  time = null;
  horaprefix = "";
  portao = "";
  status = "";
  status2 = "";
  tomorow = false;
  estimativa = "";
  teste = "";
  cor = "0";
  dia = null;

}




// export class DataResponse {
//   response = '';
// }


// export class DataAirport {
//   response = '';
// }

// export class DataPluginData {
//   response = '';
// }
// export class DataSchedule {
//   response = '';
// }
// export class DataDepartures {
//   response = '';
// }
// export class DataData {
//   response = '';
// }

// export class DataData {
//   response = '';
// }
