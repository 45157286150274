import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Flight } from '../Models/Flight.model';


@Injectable({ providedIn: 'root' })
export class DataService {
  private URL_IN: string;
  private URL_OUT: string;

  constructor(
    private http: HttpClient,
  ) {

                              /* Cascavel */

    this.URL_IN = "https://nroooik7xh.execute-api.us-east-2.amazonaws.com/cacin";
    this.URL_OUT = "https://nroooik7xh.execute-api.us-east-2.amazonaws.com/cacout"; 

                                  /* Toledo */

   /*  this.URL_IN = "https://nroooik7xh.execute-api.us-east-2.amazonaws.com/vooin";
    this.URL_OUT = "https://nroooik7xh.execute-api.us-east-2.amazonaws.com/vooout"; */






  }

  async getIN() {

    var timeIncrement = parseInt(localStorage.getItem('timeIncrement') ?? "0");

    let retorno =  await this.http.get<any>(this.URL_IN).toPromise();

    let result = retorno.result.response.airport.pluginData.schedule.arrivals.data;
   // console.log(result);

   var options = {hour: "numeric", minute: "numeric", timezone: "America/Sao_Paulo"}

    let flights = [];
    result.forEach(element => {
        let f = new Flight();
        f.empresa =  element.flight.airline.code.icao;
        f.voo =  element.flight.identification.number.default;
        f.voo = f.voo.substring(2, 6);
        f.portao =  element.flight.airport.origin.info.gate ?? "-";
        // if(f.portao === "" || f.portao == null){
        //   f.portao = "-";
        // }
        console.log(f.portao);
        f.cidade =  element.flight.airport.origin.position.region.city.toUpperCase();
        f.aeroporto =  element.flight.airport.origin.code.iata;
        f.aeroporto2 =  element.flight.status.text;
      
        var timestamp =  element.flight.time.scheduled.arrival;
        var date = new Date((timestamp * 1000) + timeIncrement );

        var dataSimples = date.toLocaleString('pt-BR',options);
        f.hora = dataSimples;
        f.time = date;


        var estimativaTimeStamp =  element.flight.time.estimated.arrival ;
        if(estimativaTimeStamp != null && estimativaTimeStamp > 0){
          var date = new Date(estimativaTimeStamp * 1000 + timeIncrement);
          var dataSimples = date.toLocaleString('pt-BR',options);
          f.estimativa = dataSimples;
        }


        

        if(element.flight.status.text.includes('Estimated')){


          f.status = f.aeroporto2;
          f.status = f.status.substring(9, 15);
          f.status = f.status.replace(" ","Previsão ");
          f.cor = "Previsão";
          f.status2 = "";
        }



        console.log(element.flight.status.text);
        if(element.flight.status.text.includes('Landed')){

          f.status = f.aeroporto2;
          f.status = f.status.substring(6, 12);
          f.status = f.status.replace(" ","Pousou ");
          f.status2 = "006400";
        }

        





        if(element.flight.status.text.includes('Scheduled')){   
          
          if(f.empresa == ('GLO')){
            f.status = "Aguardando Dados";
          }
          else{
            f.status = "AGENDADO";
          }

        }


        if(element.flight.status.text.includes('Canceled')){
          f.status = "CANCELADO";
          f.status2 = "FF0000";
          /* f.cor = "Poblema"; */
        }


        if(element.flight.status.text.includes('Delayed')){ 
          f.status = f.aeroporto2;
          f.status = f.status.substring(7, 13);
          f.status = f.status.replace(" ","Prev. Pouso as ");
          f.status2 = "FF0000";
        }


        if(element.flight.status.text.includes('Diverted')){ 
          f.status = "ALTERNADO";
          f.status2 = "FF0000";
          /* f.cor = "Poblema"; */
        }



        if(element.flight.status.text.includes('Departed')){ 
          f.status = f.aeroporto2;
          f.status = f.status.substring(8, 14);
          f.status = f.status.replace(" ","Decolou ");

          f.status2 = "";
        }




        //  f.estimativa = "10:04";
       if(this.isToday(f.time)){
         f.tomorow = false;
       }
       else{
         f.tomorow = true;
       }
        if(  flights.length < 8 ) {
          flights.push(f);
         }

    });


    return flights;

  }


  async getOUT() {

    var timeIncrement = parseInt(localStorage.getItem('timeIncrement') ?? "0");
    console.log(timeIncrement);
    let retorno =  await this.http.get<any>(this.URL_OUT).toPromise();

    let result = retorno.result.response.airport.pluginData.schedule.departures.data;
    // console.log(result);
    var options = {hour: "numeric", minute: "numeric", timezone: "America/Sao_Paulo"}
    let flights = [];
    result.forEach(element => {
        let f = new Flight();
        f.empresa =  element.flight.airline.code.icao;
        f.voo =  element.flight.identification.number.default;
        f.voo = f.voo.substring(2, 6);
        f.portao =  element.flight.airport.origin.info.gate ?? "-";
        // if(f.portao === "" || f.portao == null){
        //   f.portao = "-";
        // }

        f.cidade =  element.flight.airport.destination.position.region.city.toUpperCase();
        f.aeroporto =  element.flight.airport.destination.code.iata;
        f.aeroporto2 =  element.flight.status.text;
        var timestamp =  element.flight.time.scheduled.departure;
        var date = new Date((timestamp * 1000) + timeIncrement );
        var dataSimples = date.toLocaleString('pt-BR',options);
        f.hora = dataSimples;
        f.time = date;

        var estimativaTimeStamp =  element.flight.time.estimated.departure;
        if(estimativaTimeStamp != null && estimativaTimeStamp > 0){
          var date = new Date(estimativaTimeStamp * 1000 + timeIncrement);
          var dataSimples = date.toLocaleString('pt-BR',options);
          f.estimativa = dataSimples;

        }

        if(element.flight.status.text.includes('Estimated')){


          f.status = f.aeroporto2;
          f.status = f.status.substring(13, 20);
          f.status = f.status.replace(" ","Previsão ");
          f.cor = "Previsão";
          f.status2 = "";
        }



        console.log(element.flight.status.text);
        if(element.flight.status.text.includes('Landed')){

          f.status = f.aeroporto2;
          f.status = f.status.substring(6, 12);
          f.status = f.status.replace(" ","Pousou ");
          f.status2 = "006400";
        }

    


        if(element.flight.status.text.includes('Scheduled')){   
          
          if(f.empresa == ('GLO')){
            f.status = "Aguardando Dados";
          }
          else{
            f.status = "AGENDADO";
          }

        }


        if(element.flight.status.text.includes('Canceled')){
          f.status = "CANCELADO";
          f.status2 = "FF0000";
          /* f.cor = "Poblema"; */
        }


        if(element.flight.status.text.includes('Delayed')){ 
          f.status = f.aeroporto2;
          f.status = f.status.substring(7, 13);
          f.status = f.status.replace(" ","Prev. Pouso as ");
          f.status2 = "FF0000";
          f.cor = "Poblema";
        }

        if(element.flight.status.text.includes('Diverted')){ 
          f.status = "ALTERNADO";
          f.status2 = "FF0000";
          /* f.cor = "Poblema"; */
        }



        if(element.flight.status.text.includes('Departed')){
          f.status = f.aeroporto2;
          f.status = f.status.substring(8, 14);
          f.status = f.status.replace(" ","Decolou ");
          
          /* f.cor = "delocou"; */


          f.status2 = "006400";
        }







        if(this.isToday(f.time)){
          f.tomorow = false;
        }
        else{
          f.tomorow = true;
        }
        console.log(f.hora);
       // if(this.isToday(f.time)){
         // flights.push(f);
       // }

       if(  flights.length < 8 ) {
        flights.push(f);
       }


    });

    // flights.sort(function(a,b){
    //   return b.time - a.time;
    // });

    return flights;

  }

  isToday(someDate)  {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  formataHora(date) { // This is to display 12 hour format like you asked
    var hours = date.getHours();
    var minutes = date.getMinutes();
    // var ampm = hours >= 12 ? 'pm' : 'am';
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' ;
    return strTime;
  }


}
